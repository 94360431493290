<template>
  <div id="assigned-class" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <div class="columns" v-else>
      <div class="column is-6 is-offset-3">
        <ValidationObserver ref="observer" v-slot="{ passes }">
          <form @submit.prevent="passes(submit)">
            <SdInput
              type="text"
              rules="required"
              label="School Class"
              v-model="assignedClass.schoolClass.name"
              readonly
            />
            <SdSelect rules="required" label="Educator" v-model="educatorId">
              <option
                v-for="educator in educators"
                :key="educator.id"
                :value="educator.id"
              >
                {{ educator.name }}
              </option>
            </SdSelect>
            <button
              type="submit"
              class="button btn-120 is-primary is-capitalized is-pulled-right"
            >
              Submit
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import EDUCATORS from '../../../graphql/educator/Educators.gql'
import SdInput from '../../components/SdInput'
import SdSelect from '../../components/SdSelect'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'assigned-class',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Assigned Class',
      educators: [],
      educatorId: null,
      schoolClasses: [],
      assignedClass: {
        id: null,
        educator: {
          id: null,
          name: null,
        },
        schoolClass: {
          id: null,
          name: null,
        },
      },
    }
  },
  apollo: {
    assignedClass: {
      query: gql`
        query AssignedClassQuery($id: ID!) {
          assignedClass(id: $id) {
            id
            educator {
              id
              name
            }
            schoolClass {
              id
              name
            }
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
    },
    educators: {
      query: EDUCATORS,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
  },
  watch: {
    assignedClass(value) {
      this.educatorId = value.educator.id
    },
  },
  methods: {
    submit() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateAssignedSchoolClassQuery(
              $id: Int!
              $educatorId: Int!
            ) {
              updateAssignedSchoolClass(
                input: { id: $id, educatorId: $educatorId }
              ) {
                assignedClass {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.assignedClass.id),
            educatorId: parseInt(this.educatorId),
          },
        })
        .then((response) => {
          crudNotification(
            response.data.updateAssignedSchoolClass.errors,
            'Successfully updated.'
          ).then(() => {
            this.$router.push(`/school/${this.schoolId}/assigned_classes`)
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  components: {
    SdInput,
    SdSelect,
    ValidationObserver,
  },
  mounted() {
    if (!this.$route.params.id)
      this.$router.push(
        `/school/${this.$route.params.school_id}/assigned_classes`
      )
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Assigned Classes',
        route: `/school/${this.schoolId}/assigned_classes`,
      },
    ])
  },
}
</script>

<style lang="scss" scoped></style>
